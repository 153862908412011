import {EChannelType} from 'src/constants';

import {ETariffChangeRequestStatus} from './definitions';

export default {
  personal: 'Personal',
  free: '(free)',
  mauCount: 'MAU {{mauCount}}',
  bynTariff: '{{channel}}_BYN',
  acceptTariff: {
    title: 'Accept your pricing',
    subtitle:
      'Familiarize yourself with the pricing and click "{{buttonName}}". Once the pricing is accepted, the channels are available for registration',
  },
  agreeTariffChange: {
    title: 'Your pricing has been changed',
    subtitle:
      'Your current pricing has been changed upon your request. Accept the new pricing for the changes to take effect. ',
    text: '<0>New pricing:</0> {{newTariffName}},<br/> <0>for the:</0> {{subjectName}}',
  },
  tariffUpdate: {
    title: '[en] Изменить действующий тариф {{name}}',
    scheduleUpdate: '[en] Запланировать изменение',
    error:
      '[en] Тариф не изменен. Для этой подписи уже есть запланированное изменение позже, чем первый день следующего месяца.',
    subjectMessage:
      '[en] Стоимость лицензии будет изменена <strong>для всех подписей</strong> в тенанте.',
    whatsAppSubjectMessage:
      '[en] Стоимость лицензии, MAU, стоимость подключения и стоимость AMAU будут изменены <strong>для конкретной подписи.</strong>',
    message:
      '[en] Изменения вступят в силу в 00:00 первого числа следующего месяца. Рекомендуем планировать изменение тарифа за 2 дня до этой даты.',
    scheduled: '[en] Запланировано изменение тарифа с {{date}}',
    fileLabel:
      '[en] Загрузите согласованный файл с индивидуальной стоимостью сообщений в валюте согласно условиям договора.',
    tariffName: '[en] Название тарифа (обязательно, должно быть уникальным в рамках тенанты)',
    attachFile: '[en] Прикрепить файл XLSX',
    attachAnotherFile: '[en] Прикрепить другой файл',
    optionalHint:
      '[en] В тарифе будут изменены только те параметры, которые вы поменяете. Остальные параметры останутся прежними.',
    uniqueNameError: '[en] Это название не уникально для тенанты',
    [EChannelType.WHATSAPP]: {
      message:
        '[en] <0>Стоимость лицензии, MAU, стоимость подключения и стоимость AMAU будут изменены <strong>для конкретной подписи.</strong></0><0>Изменения вступят в силу в 00:00 первого числа следующего месяца. Рекомендуем планировать изменение тарифа за 2 дня до этой даты.</0>',
    },
    [EChannelType.EMBEDDED_WHATSAPP]: {
      message:
        '[en] <0>Стоимость лицензии, MAU, стоимость подключения и стоимость AMAU будут изменены <strong>для конкретной подписи.</strong></0><0>Изменения вступят в силу в 00:00 первого числа следующего месяца. Рекомендуем планировать изменение тарифа за 2 дня до этой даты.</0>',
    },
    [EChannelType.VIBER]: {
      message:
        '[en] <0>Изменения вступят в силу в 00:00 (МСК) первого числа следующего месяца для <strong>конкретной подписи.</strong> Рекомендуем планировать изменение тарифа за 2 дня до этой даты. Если изменяется стоимость лицензии, ее необходимо изменить у всех тарифов Viber в этой тенанте.</0>',
    },
    [EChannelType.SMS]: {
      message:
        '[en] <0>Изменения вступят в силу в 00:00 (МСК) первого числа следующего месяца для <strong>конкретной подписи.</strong> Рекомендуем планировать изменение тарифа за 2 дня до этой даты. Если изменяется стоимость лицензии, ее необходимо изменить у всех тарифов СМС в этой тенанте.</0>',
    },
  },
  tariffHistory: {
    title: 'Pricing change history',
    scheduledTitle: '[en] Запланировано изменение тарифа',
    active: 'Active pricing: ',
    new: '[en] Новый тариф: ',
    changed: '<0>The pricing has been changed on {{date}}</0><br/><1>{{active}}</1><2>{{name}}<2/>',
    scheduled: '[en] <0>{{date}} Тариф будет изменен</0><br/><1>{{active}}</1><2>{{name}}<2/>',
    connected:
      '<0>The pricing has been connected on {{date}}</0><br/><1>{{active}}</1><2>{{name}}<2/>',
    individual: 'Individual',
  },
  successCreatingTariff: {
    title: 'Congratulations! You have successfully created a rate!',
    text: 'You can continue with the new rate',
  },
  createPersonalTariff: {
    title: 'Create a personal {{channelType}} rate',
    message:
      'Once created, you cannot edit a personal rate. If you need to change it, you will have to do it through the support team',
    templateTitleError: 'This rate template title is already exist',
    selectTemplateError: 'Select tariff template',
    successUpload: '{{name}} tariff data updated successfully',
    errorUpload: 'Failed to update {{name}} tariff data',
    successFileUpload: '{{name}} tariff data file uploaded successfully',
    errorFileUpload: 'Failed to upload {{name}} tariff data file',
    labels: {
      selectTemplate: 'Select a rate template based on which you want to create the personal rate',
      templateName:
        'A rate template title must be unique for you dashboard. The name will be shown to the client.',
      mauCount: 'Number of MAU',
      licensePrice: 'License cost',
      licensePriceWithCurrency: 'License cost, {{currency}}',
      additionalMauCost: 'Additional MAU cost, {{currency}}',
      uploadFile: 'Upload the agreed file with the cost of messages',
      uploadFileWA:
        'upload the agreed file with the individual cost of dialogs in currency, which you have selected on the first step of the client registration. For ОСК clients the cost of messages must be in USD.',
      country: 'Country',
      businessDialog: 'Business-initiated conversation',
      userDialog: 'User-initiated conversation',
      initPayment: 'Service connection cost, {{currency}}',
    },
  },
  tariffCostModal: {
    title: 'The cost of conversations',
    dialogsCost: 'Cost of conversations, {{currency}}',
  },
  button: {
    refuse: 'Refuse',
    acceptTariff: 'Accept pricing',
    agreeTariff: 'Agree pricing',
    fine: 'Fine!',
  },
  status: {
    [ETariffChangeRequestStatus.NEED_TO_AGREE]: 'Need to agree',
    [ETariffChangeRequestStatus.AGREED]: 'Agreed',
    [ETariffChangeRequestStatus.PROCESSED]: 'Processed',
    [ETariffChangeRequestStatus.CANCELED]: 'Canceled',
    [ETariffChangeRequestStatus.REJECTED]: 'Rejected',
    [ETariffChangeRequestStatus.NOT_AGREED]: 'Not agreed',
  },
};
