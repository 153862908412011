import {EPaymentCorrectionType} from './PaymentsTab';
import {ETariffActionType} from './TariffsTab';
import {ECorrectionsTab} from './definitions';

export default {
  tabs: {
    [ECorrectionsTab.PAYMENTS]: '[en] Списания и оплаты',
    [ECorrectionsTab.LICENSES]: 'Licenses',
    [ECorrectionsTab.TARIFFS]: '[en] Изменения тарифов',
  },
  payments: {
    storno: '[en] Сторнировать операцию',
    selectType: '[en] Выберите тип операции',
    notFound: '[en] Операция не найдена',
    type: {
      [EPaymentCorrectionType.STORNO]: '[en] Сторнировать операцию',
      [EPaymentCorrectionType.CANCEL_STORNO]: '[en] Отменить сторно',
    },
    enterEventId: '[en] Укажите ID операции',
    searchResults: '[en] Результаты поиска',
    companyName: 'Company name',
    paymentType: 'Payment type',
    licensePayment: 'License payment',
    amount: 'Amount',
    currency: 'Currency',
    isSearchCorrectMessage: '[en] Обязательно убедитесь в корректности запроса по таблицам',
    billedEvent: 'Billed event',
    debtTransaction: 'Debt transaction',
    transaction: 'Transaction',
    id: 'ID',
    originalEventId: 'Original event ID',
    originalEventType: 'Original event Type',
    confirmation: 'Confirmation',
    createdAt: 'Created at',
    eventId: 'Event ID',
    tenantId: '[en] ID компании',
    reason: 'Reason',
    reasonCode: 'Reason code',
    balanceId: 'Balance ID',
    openingBalance: 'Opening balance',
    state: 'State',
    updated: 'Updated',
    search: 'Search',
    comment: 'Comment',
    button: {
      [EPaymentCorrectionType.STORNO]: '[en] Сторнировать',
      [EPaymentCorrectionType.CANCEL_STORNO]: '[en] Отменить сторно',
    },
    confirmModal: {
      title: {
        [EPaymentCorrectionType.STORNO]: '[en] Уверены? Мы отменяем списание',
        [EPaymentCorrectionType.CANCEL_STORNO]: '[en] Уверены? Мы отменяем сторно',
      },
      message: '[en] Если это ошибочная операция, то исправить это будет невозможно',
      subtitle: '[en] Проверьте данные:',
      button: '[en] Да, продолжить',
    },
    successModal: {
      title: '[en] Все хорошо!',
      message: '[en] Запрос принят к исполнению',
    },
  },
  licenses: {
    channelType: 'Channel type',
    licenseID: 'License ID',
    month: 'Month',
    year: 'Year',
    eventTable: 'license_billed_event_tab (for the specified period)',
    notFound: 'License not found',
    search: 'Search',
    notification: 'License renewal operation started',
    tr: {
      id: 'ID',
      channel: 'Channel',
      price: 'Price',
      currency: 'Currency',
      paymentType: 'Payment type',
      periodStartedAt: 'Period started at',
      periodEndedAt: 'Period ended at',
      transactionId: 'Transaction ID',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      documentId: 'Document ID',
      tenantId: 'Tenant ID',
      subjectId: 'SubjectID',
      periodMonths: 'Period months ',
      name: 'Name',
      description: 'Description',
      licenseStatus: 'License status',
      activatedAt: 'Activated at',
      expiredAt: 'Expired at',
      cancelledAt: 'Cancelled at',
      activeUntil: 'Active until',
      paidUntil: 'Paid until',
      temporaryblockedAt: 'Temporary blocked at',
      paymentCode: 'Payment code',
    },
  },
  tariffs: {
    updateInfo:
      '[en] В тарифах изменяются только данные, которые вы ввели в процессе изменения (через UI или из файла). Незаполненные данные в тарифах не обновятся.',
    updateWarning:
      '[en] Вы собираетесь массово изменить тарифы. Убедитесь в корректности  данных перед изменением!',
    mainSection: '[en] Массовое действие с тарифом',
    actionType: '[en] Выберите действие с тарифом',
    actionTypePlaceholder: '[en] Действие с тарифом',
    tariffType: '[en] Выберите тип тарифа',
    tariff: '[en] Тариф',
    tariffTemplate: '[en] Шаблон тарифа',
    channelType: '[en] В каком канале меняется тариф',
    channelTypePlaceholder: '[en] Выберите канал',
    newSection: '[en] Новые данные для тарифа',
    licensePrice: '[en] Изменить стоимость лицензии',
    licensePriceWA: '[en] Изменить стоимость лицензии (licensePrice)',
    mauCount: '[en] Количество MAU (mauCount)',
    initPayment: '[en] Подключение (initPayment)',
    defaultMauPrice: '[en] Стоимость AMAU (defaultMauPrice)',
    file: '[en] Загрузите согласованный файл с индивидуальной стоимостью сообщений или диалогов',
    date: '[en] Тариф вступит в силу',
    tariffIds: '[en] Перечислите ID тарифов для изменения',
    comment: {
      [ETariffActionType.REPLACE]: '[en] Комментарий по изменению',
      [ETariffActionType.UPDATE]: '[en] Комментарий по изменению',
      [ETariffActionType.DELETE]: '[en] Комментарий по удалению',
    },
    commentPlaceholder:
      '[en] К примеру, номер обращения в саппорт, по которому делаются эти изменения',
    actionOption: {
      [ETariffActionType.REPLACE]: '[en] Полная замена',
      [ETariffActionType.UPDATE]: '[en] Обновление тарифа',
      [ETariffActionType.DELETE]: '[en] Удалить параметры тарифа',
    },
    submitButton: {
      [ETariffActionType.REPLACE]: '[en] Изменить параметры',
      [ETariffActionType.UPDATE]: '[en] Изменить параметры',
      [ETariffActionType.DELETE]: '[en] Удалить параметры',
    },
    successTariffTemplateMessage: 'Параметры шаблонов тарифов успешно обновлены',
    successTariffMessage: {
      [ETariffActionType.REPLACE]:
        '[en] Запланирована полная замена параметров тарифов на {{date}}',
      [ETariffActionType.UPDATE]:
        '[en] Запланировано массовое изменение параметров тарифов на {{date}}',
      [ETariffActionType.DELETE]:
        '[en] Запланировано массовое удаление параметров тарифов на {{date}}',
    },
    confirmModal: {
      title: {
        [ETariffActionType.REPLACE]:
          '[en] Уверены? Вы собираетесь полностью заменить параметры тарифов',
        [ETariffActionType.UPDATE]:
          '[en] Уверены? Вы собираетесь массово изменить параметры тарифов',
        [ETariffActionType.DELETE]:
          '[en] Уверены? Вы собираетесь массово удалить параметры тарифов',
      },
      check: '[en] Проверьте данные:',
      channel: '[en] Канал:',
      tariffType: '[en] Тип тарифа:',
      action: '[en] Действие с тарифом:',
      actionOption: {
        [ETariffActionType.REPLACE]: '[en] Полная замена параметров тарифа',
        [ETariffActionType.UPDATE]: '[en] Изменение параметров тарифа',
        [ETariffActionType.DELETE]: '[en] Удаление параметров тарифа',
      },
      licensePrice: '[en] Стоимость лицензии:',
      mauCount: '[en] Количество MAU:',
      initPayment: '[en] Подключение:',
      defaultMauPrice: '[en] Стоимость AMAU:',
      file: '[en] Прикрепленный файл:',
      date: '[en] Дата вступления в силу изменения:',
      tariffIds: '[en] ID изменяемых тарифов:',
    },
  },
};
