import {EChannelType} from 'src/constants';

import {ETariffChangeRequestStatus} from './definitions';

export default {
  personal: 'Индивидуальный ',
  free: '(бесплатное подключение)',
  mauCount: 'MAU {{mauCount}}',
  bynTariff: '{{channel}}_BYN',
  acceptTariff: {
    title: 'Примите ваши тарифы',
    subtitle:
      'Ознакомьтесь с тарифами и нажмите кнопку "{{buttonName}}". После принятия тарифы станут доступными для регистрации каналов',
  },
  agreeTariffChange: {
    title_one: 'У вас поменялся тариф',
    title_few: 'У вас поменялись тарифы',
    title_many: 'У вас поменялись тарифы',
    subtitle:
      'По вашему запросу текущий тариф был изменен. Чтобы изменения вступили в силу, вы должны принять новый тариф',
    text: '<0>Новый тариф:</0> {{newTariffName}}, <br/> <0>для канала:</0> {{subjectName}}',
  },
  tariffUpdate: {
    title: 'Изменить действующий тариф {{name}}',
    scheduleUpdate: 'Запланировать изменение',
    error:
      'Тариф не изменен. Для этой подписи уже есть запланированное изменение позже, чем первый день следующего месяца.',
    subjectMessage:
      'Стоимость лицензии будет изменена <strong>для всех подписей</strong> в тенанте.',
    whatsAppSubjectMessage:
      'Стоимость лицензии, MAU, стоимость подключения и стоимость AMAU будут изменены <strong>для конкретной подписи.</strong>',
    message:
      'Изменения вступят в силу в 00:00 первого числа следующего месяца. Рекомендуем планировать изменение тарифа за 2 дня до этой даты.',
    scheduled: 'Запланировано изменение тарифа с {{date}}',
    fileLabel:
      'Загрузите согласованный файл с индивидуальной стоимостью сообщений в валюте согласно условиям договора.',
    tariffName: 'Название тарифа (обязательно, должно быть уникальным в рамках тенанты)',
    attachFile: 'Прикрепить файл XLSX',
    attachAnotherFile: 'Прикрепить другой файл',
    optionalHint:
      'В тарифе будут изменены только те параметры, которые вы поменяете. Остальные параметры останутся прежними.',
    uniqueNameError: 'Это название не уникально для тенанты',
    [EChannelType.WHATSAPP]: {
      message:
        '<0>Стоимость лицензии, MAU, стоимость подключения и стоимость AMAU будут изменены <strong>для конкретной подписи.</strong></0><0>Изменения вступят в силу в 00:00 первого числа следующего месяца. Рекомендуем планировать изменение тарифа за 2 дня до этой даты.</0>',
    },
    [EChannelType.EMBEDDED_WHATSAPP]: {
      message:
        '<0>Стоимость лицензии, MAU, стоимость подключения и стоимость AMAU будут изменены <strong>для конкретной подписи.</strong></0><0>Изменения вступят в силу в 00:00 первого числа следующего месяца. Рекомендуем планировать изменение тарифа за 2 дня до этой даты.</0>',
    },
    [EChannelType.VIBER]: {
      message:
        '<0>Изменения вступят в силу в 00:00 (МСК) первого числа следующего месяца для <strong>конкретной подписи.</strong> Рекомендуем планировать изменение тарифа за 2 дня до этой даты. Если изменяется стоимость лицензии, ее необходимо изменить у всех тарифов Viber в этой тенанте.</0>',
    },
    [EChannelType.SMS]: {
      message:
        '<0>Изменения вступят в силу в 00:00 (МСК) первого числа следующего месяца для <strong>конкретной подписи.</strong> Рекомендуем планировать изменение тарифа за 2 дня до этой даты. Если изменяется стоимость лицензии, ее необходимо изменить у всех тарифов СМС в этой тенанте.</0>',
    },
  },
  tariffHistory: {
    title: 'История изменения тарифа',
    scheduledTitle: 'Запланировано изменение тарифа',
    active: 'Действующий тариф: ',
    new: 'Новый тариф: ',
    changed: '<0>{{date}} Тариф изменен</0><br/><1>{{active}}</1><2>{{name}}<2/>',
    connected: '<0>{{date}} Тариф подключен</0><br/><1>{{active}}</1><2>{{name}}<2/>',
    scheduled: '<0>{{date}} Тариф будет изменен</0><br/><1>{{active}}</1><2>{{name}}<2/>',
    individual: 'Индивидуальный',
  },
  successCreatingTariff: {
    title: 'Поздравляем! Вы успешно создали тариф!',
    text: 'Теперь вы можете продолжить с новым тарифом',
  },
  createPersonalTariff: {
    title: 'Создать индивидуальный тариф {{channelType}}',
    message:
      'После создания индивидуальный тариф отредактировать нельзя. Если потребуются изменения, они будут вноситься только через саппорт',
    templateTitleError: 'Шаблон тарифа с таким названием уже существует',
    selectTemplateError: 'Выберите шаблон тарифа',
    successUpload: 'Данные тарифа {{name}} успешно обновлены',
    errorUpload: 'Ошибка обновления данных тарифа {{name}}',
    successFileUpload: 'Файл с данными тарифа {{name}} успешно загружен',
    errorFileUpload: 'Файл с данными тарифа {{name}} не загружен',
    labels: {
      selectTemplate:
        'Выберите шаблон тарифа, на основании которого будет создан индивидуальный тариф',
      templateName:
        'Название индивидуального шаблона тарифа (должно быть уникальным для вашего личного кабинета). Имя будет отображаться клиенту',
      mauCount: 'Количество MAU',
      licensePrice: 'Стоимость лицензии',
      licensePriceWithCurrency: 'Стоимость лицензии, {{currency}}',
      additionalMauCost: 'Стоимость дополнительных MAU, {{currency}}',
      uploadFile: 'Загрузите согласованный файл со стоимостью сообщений',
      uploadFileWA:
        'Загрузите согласованный файл с индивидуальной стоимостью диалогов в валюте, которую вы указали на 1ом шаге регистрации клиента. Для клиентов ОСК стоимость сообщений указывается в USD.',
      country: 'Страна',
      businessDialog: 'Бизнес-диалог',
      userDialog: 'Клиентский диалог',
      initPayment: 'Стоимость подключения, {{currency}}',
    },
  },
  tariffCostModal: {
    title: 'Стоимость за диалоги',
    dialogsCost: 'Стоимость диалогов, {{currency}}',
  },
  button: {
    refuse: 'Отказаться',
    agreeTariff: 'Согласовать тариф',
    acceptTariff_one: 'Принять тариф',
    acceptTariff_few: 'Принять тарифы',
    acceptTariff_many: 'Принять тарифы',
    fine: 'Хорошо!',
  },
  status: {
    [ETariffChangeRequestStatus.NEED_TO_AGREE]: 'На согласовании',
    [ETariffChangeRequestStatus.AGREED]: 'Согласован',
    [ETariffChangeRequestStatus.PROCESSED]: 'На рассмотрении',
    [ETariffChangeRequestStatus.CANCELED]: 'Отменен',
    [ETariffChangeRequestStatus.REJECTED]: 'Отклонен',
    [ETariffChangeRequestStatus.NOT_AGREED]: 'Не согласован',
  },
};
