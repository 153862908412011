import styled, {css} from 'styled-components';

import {
  NewDatePickerField as BaseDatePickerField,
  LoadingButton as BaseLoadingButton,
  NewNumberInputField as BaseNumberInputField,
  NewSelectField as BaseSelectField,
  Td as BaseTd,
  Th as BaseTh,
  NewTimePickerField as BaseTimePickerField,
} from '@edna/components';
import {Column as BaseColumn} from '@edna/components/primitives';
import {mixins} from '@edna/components/styles';
import {TSize} from '@edna/components/types';

import {StatusIcon as BaseStatusIcon} from 'src/components';
import {BigText as BaseBigText, getFontSize} from 'src/components/primitives';

export const SelectField = styled(BaseSelectField)`
  max-width: 400px;
  margin: 0;
`;

export const NumberInputField = styled(BaseNumberInputField)`
  max-width: 180px;
  margin: 0;
`;

export const TimePickerField = styled(BaseTimePickerField)`
  max-width: 94px;
  margin-bottom: 0;
`;

export const DatePickerField = styled(BaseDatePickerField)`
  max-width: 180px;
  margin-bottom: 0;
`;

type TCenterRowProps = {
  size?: TSize;
};

export const InfoRow = styled.div<TCenterRowProps>`
  ${({size}) => getFontSize(size)};
  ${mixins.flexAlign('initial', 'center')}
  flex-flow: row wrap;
`;

export const DetailsLabel = styled.div`
  ${({theme}) => css`
    color: ${theme.branding.content.secondary};
    margin-right: ${theme.spacing.s1};
    &::after {
      content: ':';
    }
  `}
`;
export const Icon = styled.div`
  ${mixins.wh('24px')}
  margin-right: ${({theme}) => theme.spacing.s1};
`;
export const StatusIcon = styled(BaseStatusIcon)`
  ${mixins.wh('12px')}
  flex-shrink: 0;
  ${({theme}) => css`
    margin: 0 ${theme.spacing.s1} ${theme.spacing.s1} 0;
  `}
`;

export const BilledEventTables = styled.div`
  margin-bottom: ${({theme}) => theme.spacing.s4};
`;

export const BigText = styled(BaseBigText)`
  margin-bottom: ${({theme}) => theme.spacing.s2};
`;

export const Column = styled(BaseColumn)`
  margin-bottom: ${({theme}) => theme.spacing.s4};
`;

export const Td = styled(BaseTd)`
  min-width: 140px;
`;

export const Th = styled(BaseTh)`
  min-width: 140px;
`;

export const SuccessImage = styled.svg`
  ${mixins.wh('350px', '250px')};
`;

export const SearchButton = styled(BaseLoadingButton)`
  margin-top: 28px;
`;

export const LicenseCreateButton = styled(BaseLoadingButton)`
  width: fit-content;
  margin-top: ${({theme}) => theme.spacing.s4};
`;
