import React from 'react';
import {useTranslation} from 'react-i18next';

import isNil from 'lodash/isNil';

import {
  Modal,
  ModalActions,
  ModalCancelButton,
  ModalContent,
  ModalHeader,
  ModalSubmitButton,
  showModal,
} from '@edna/components';
import {Stack} from '@edna/components/primitives';

import {SecondaryText, Text} from 'src/components/primitives';
import {CHANNEL_TYPE} from 'src/constants';
import {parseISO} from 'src/utils';

import {useChangeTariffsMutation} from '../api';
import {TFormData} from './definitions';
import {prepareDataForSave} from './utils';

type TFieldProps = {
  name: string;
  children?: React.ReactNode;
};

const Field = React.memo<TFieldProps>(({name, children}) => {
  const {t} = useTranslation();

  if (isNil(children)) {
    return null;
  }

  return (
    <Stack direction="ROW" size="1">
      <SecondaryText>{t(`Corrections:tariffs.confirmModal.${name}`)}</SecondaryText>
      <Text>{children}</Text>
    </Stack>
  );
});

Field.displayName = 'Field';

type TProps = {
  onSuccess?: TEmptyFunction;
  values: TFormData;
};

const ConfirmModal = React.memo<TProps & {closeModal: TEmptyFunction}>(
  ({closeModal, onSuccess, values}) => {
    const {t} = useTranslation();

    const [changeTariffs] = useChangeTariffsMutation();

    const data = prepareDataForSave(values);

    const handleSubmit = React.useCallback(async () => {
      try {
        await changeTariffs(data).unwrap();

        closeModal();
        onSuccess?.();
      } catch (e) {}
    }, [onSuccess, data, closeModal]);

    return (
      <Modal width="582px">
        <ModalHeader>{t(`Corrections:tariffs.confirmModal.title.${values.action}`)}</ModalHeader>
        <ModalContent>
          <Text bottomIndent="4">{t('Corrections:tariffs.confirmModal.check')}</Text>
          <Field name="channel">{CHANNEL_TYPE[data.channelType]}</Field>
          <Field name="tariffType">
            {t(`Corrections:tariffs.${data.isTariffTemplate ? 'tariffTemplate' : 'tariff'}`)}
          </Field>
          <Field name="action">
            {t(`Corrections:tariffs.confirmModal.actionOption.${data.action}`)}
          </Field>
          <Field name="licensePrice">{data.licensePrice}</Field>
          <Field name="mauCount">{data.mauCount}</Field>
          <Field name="initPayment">{data.initPayment}</Field>
          <Field name="defaultMauPrice">{data.defaultMauPrice}</Field>
          <Field name="file">{data.file?.name}</Field>
          <Field name="date">{data.from ? parseISO(data.from, 'dd.MM.yyyy HH:mm') : null}</Field>
          <Field name="tariffIds">{data.idToUpdate.join(', ')}</Field>
        </ModalContent>
        <ModalActions>
          <ModalCancelButton onClick={closeModal}>{t('Common:button.cancel2')}</ModalCancelButton>
          <ModalSubmitButton onClick={handleSubmit}>
            {t(`Corrections:tariffs.submitButton.${values.action}`)}
          </ModalSubmitButton>
        </ModalActions>
      </Modal>
    );
  },
);

ConfirmModal.displayName = 'ConfirmModal';

const showConfirmModal = ({onSuccess, values}: TProps) =>
  showModal((closeModal) => (
    <ConfirmModal closeModal={closeModal} onSuccess={onSuccess} values={values} />
  ));

export {showConfirmModal};
