import {addMinutes} from 'date-fns/addMinutes';
import {addMonths} from 'date-fns/addMonths';
import {subDays} from 'date-fns/subDays';

import {CHANNEL_TYPE, EChannelType} from 'src/constants';

enum ETariffActionType {
  REPLACE = 'REPLACE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

type TTariffUpdate = {
  idToUpdate: number[];
  channelType: EChannelType;
  newName: string;
  action: ETariffActionType;
  isTariffTemplate: boolean;
  from?: string;
  licensePrice?: number;
  defaultMauPrice?: number;
  mauCount?: number;
  initPayment?: number;
  file?: File;
};

type TFormData = Omit<Partial<TTariffUpdate>, 'from' | 'idToUpdate'> & {
  fromDate?: Date;
  fromTime?: string;
  file?: File;
  idToUpdate: string;
};

const DEFAULT_VALUES: TFormData = {
  isTariffTemplate: false,
  fromDate: addMonths(new Date().setDate(1), 1),
  fromTime: '00:00',
  idToUpdate: '',
};

const channelOptions = [EChannelType.SMS, EChannelType.VIBER, EChannelType.WHATSAPP].map(
  (channel) => ({
    value: channel,
    label: CHANNEL_TYPE[channel],
  }),
);

const MIN_TIME = addMinutes(new Date(), 10);
const MIN_DATE = subDays(new Date(), 1);
const MAX_DECIMAL_SCALE = 4;

export {ETariffActionType, DEFAULT_VALUES, MIN_TIME, MIN_DATE, MAX_DECIMAL_SCALE, channelOptions};
export type {TFormData, TTariffUpdate};
