export default {
  balance: 'Текущий баланс:',
  overdraft: 'Овердрафт',
  availableBalance: 'Доступные средства:',
  topUp: 'Пополнить',
  withdraw: 'Списать',
  description: 'Описание операции',
  warning:
    'Пожалуйста, внимательно заполняйте эту форму.<br/> В выписке счета наших клиентов не должно быть ошибок',
  transactionDate: 'Дата сохранения транзакции',
  topUpBalanceModal: {
    title: 'Оплатить счет компании',
    amount: 'Сумма оплаты (с точностью до копеек, как в счете)',
    description: 'Скопируйте и вставьте назначение платежа из выбранного счета',
    amountNotMatch: 'Сумма зачисления не совпадает с суммой выставленного счета. Проверьте данные',
  },
  withdrawBalanceModal: {
    title: 'Списать с баланса',
    amount: 'Cумма списания счета',
  },
  changeOverdraftModal: {
    title: 'Изменить овердрафт клиента?',
    amount: 'Размер овердрафта, {{currency}}',
    notification: 'Овердрафт изменен',
  },
  overdraftHistoryModal: {
    title: 'История изменения овердрафта',
    overdraftChanged: '{{date}} Размер овердрафта изменен',
    responsible: 'Ответственный: {{responsibleManager}}',
    newOverdraftLimit: 'Новый лимит овердрафта: ',
    overdraftLimit: 'Лимит овердрафта: ',
    overdraftInstalled: '{{date}} Размер овердрафта установлен',
  },
};
