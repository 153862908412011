import pick from 'lodash/pick';

import {EChannelType} from 'src/constants';
import {combineDateAndTime, getAbsoluteDateTime, mapEmptyFieldsToUndefined} from 'src/utils';
import {joiResolver} from 'src/utils/validator';

import {MIN_TIME, TFormData, TTariffUpdate} from './definitions';

const mapTariffIds = (value: string) => value.replace(/[^0-9,. ]+/g, '');

const parseTariffIds = (value: string) =>
  value
    .trim()
    .split(/[,. ]/)
    .map((id) => Number.parseInt(id))
    .filter(Number.isInteger);

const resolver = joiResolver((joi) =>
  joi
    .object({
      channelType: joi.string().required(),
      action: joi.string().required(),
      fromDate: joi.when('isTariffTemplate', {
        is: false,
        then: joi.date().required(),
      }),
      fromTime: joi.when('isTariffTemplate', {
        is: false,
        then: joi
          .string()
          .required()
          .custom((value, helpers) => {
            const date = combineDateAndTime(helpers.state.ancestors[0].fromDate, value);

            if (date < MIN_TIME) {
              return helpers.error('expiredTime');
            }

            return value;
          }),
      }),
      newName: joi.string().trim().required(),
      idToUpdate: joi
        .string()
        .trim()
        .required()
        .custom((value, helpers) => {
          if (parseTariffIds(value).length) {
            return value;
          }

          return helpers.error('any.required');
        }),
      mauCount: joi.number().min(1),
    })
    .custom((value, helpers) => {
      let hasAnyChanges = !!value.file || !!value.licensePrice;

      if (hasAnyChanges || value.channelType !== EChannelType.WHATSAPP) {
        return hasAnyChanges ? value : helpers.error('object.missing');
      }

      hasAnyChanges = !!value.defaultMauPrice || !!value.mauCount || !!value.initPayment;

      return hasAnyChanges ? value : helpers.error('object.missing');
    }),
);

const prepareDataForSave = (values: TFormData): TTariffUpdate => {
  const result = pick(values, [
    'channelType',
    'action',
    'newName',
    'isTariffTemplate',
    'licensePrice',
    'file',
  ]) as TTariffUpdate;

  result.idToUpdate = parseTariffIds(values.idToUpdate);

  if (!values.isTariffTemplate) {
    result.from = getAbsoluteDateTime(combineDateAndTime(values.fromDate, values.fromTime));
  }

  if (values.channelType === EChannelType.WHATSAPP) {
    result.defaultMauPrice = values.defaultMauPrice;
    result.mauCount = values.mauCount;
    result.initPayment = values.initPayment;
  }

  return mapEmptyFieldsToUndefined(result);
};

export {mapTariffIds, resolver, prepareDataForSave};
