import React from 'react';

import {ESize} from '@edna/components';

export type TVariableOption = {
  id: string | number;
  value: string;
  label: string;
};

export type TValue = string;

export type THandler = (value: TValue) => void;

export enum EInsertType {
  REGEX = 'REGEX',
  VARIABLE = 'VARIABLE',
  EMPTY_VARIABLE = 'EMPTY_VARIABLE',
}

export const REGEX_LIMIT = 10;

export type TInsertType = keyof typeof EInsertType;
export enum ERegExpType {
  STATIC = 'STATIC',
  LIMITED = 'LIMITED',
}

export type TLimitedRegexp = {
  type: ERegExpType.LIMITED;
  value: string;
  create: (value: string | number) => string;
  match: (value: string) => string;
  matchLimit: (value: string) => number;
};

export type TRegexp = TLimitedRegexp | {type: ERegExpType.STATIC; value: string};

export type TSelectedVariable = {
  type: EInsertType.VARIABLE;
  id?: TVariableOption['id'];
  value: TVariableOption['value'];
};
export type TSelectedRegexp = {type: EInsertType.REGEX; value: TRegexp; n?: number};
export type TEntity =
  | TSelectedVariable
  | TSelectedRegexp
  | {type: EInsertType.EMPTY_VARIABLE; value: string};

type TEditorWithSelectorSize = ESize.S | ESize.XS;

export type TProps = {
  name: string;
  isInvalid?: boolean;
  value?: TValue | null;
  startMatchChar?: string | string[];
  segmentsCount?: number | string | null;
  onChange?: THandler;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  type?: TInsertType;
  disabled?: boolean;
  readOnly?: boolean;
  variableOptions?: TVariableOption[];
  allowedRegexps?: TRegexp[];
  regexps?: {value: string; type: ERegExpType}[];
  rows?: number;
  isPostfix?: boolean;
  isEditableRegex?: boolean;
  isFixedMenu?: boolean;
  selector?: string;
  id?: string;
  hasFullWidth?: boolean;
  isValidateEntities?: boolean;
  hasDropdowns?: boolean;
  matchRegexp?: RegExp;
  size?: TEditorWithSelectorSize;
  isDynamicLineHeight?: boolean;
};

export type THandlerInsertParams =
  | {value: TRegexp; isString?: undefined}
  | {value: string; isString?: boolean};
