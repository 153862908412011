import {generatePath} from 'react-router';

import EditIcon from '@edna/icons/edit.svg';
import ExitIcon from '@edna/icons/exit.svg';
import HelpIcon from '@edna/icons/help.svg';

import {ECorrectionsTab} from 'src/containers/Corrections/definitions';
import {EIntegrationTabs} from 'src/containers/Integration';
import {EUserCompanyProfileTabs} from 'src/containers/UserCompanyProfile/definitions';
import {
  AnalyticsIcon,
  AudienceIcon,
  BroadcastIcon,
  HomeIcon,
  IntegrationIcon,
  NotificationsIcon,
  ProfileIcon,
  SettingsIcon,
  TriggersIcon,
} from 'src/icons';
import PATHS from 'src/paths';

export type TNavigation = {
  id: string;
  href?: string;
  icon?: TSvgComponentType;
  list?: TNavigation[];
  params?: TAnyObject;
};

export const topMenu: TNavigation[] = [
  {
    id: 'home',
    href: PATHS.HOME,
    icon: HomeIcon,
  },
  {
    id: 'audience',
    href: PATHS.SUBSCRIBERS_LIST,
    icon: AudienceIcon,
  },
  {
    id: 'broadcasts',
    href: PATHS.BROADCASTS_LIST,
    icon: BroadcastIcon,
  },
  {
    id: 'settings',
    icon: SettingsIcon,
    list: [
      {id: 'cascades', href: PATHS.CASCADES_LIST, icon: SettingsIcon},
      {id: 'channels', href: PATHS.CHANNELS_LIST, icon: SettingsIcon},
      {
        id: 'templates',
        href: PATHS.MESSAGE_MATCHERS_LIST,
        icon: SettingsIcon,
      },
    ],
  },
  {
    id: 'triggers',
    href: PATHS.TRIGGERS_LIST,
    icon: TriggersIcon,
  },
  {
    id: 'analytics',
    icon: AnalyticsIcon,
    list: [
      {
        id: 'analyticsMessages',
        href: PATHS.ANALYTICS_MESSAGES,
        icon: AnalyticsIcon,
      },
      {
        id: 'analyticsReport',
        href: PATHS.ANALYTICS_SUMMARY_REPORT,
        icon: AnalyticsIcon,
      },
    ],
  },
  {
    id: 'leadClients',
    href: PATHS.COMPANY_CLIENTS,
    icon: AudienceIcon,
  },
  {
    id: 'supportClients',
    href: PATHS.SUPPORT_CLIENTS,
    icon: AudienceIcon,
  },
  {
    id: 'corrections',
    href: generatePath(PATHS.CORRECTIONS_TAB, {
      tab: ECorrectionsTab.PAYMENTS,
    }),
    icon: EditIcon,
  },
  {
    id: 'integration',
    href: generatePath(PATHS.INTEGRATION_TAB, {
      tab: EIntegrationTabs.DOCUMENTATION,
    }),
    icon: IntegrationIcon,
  },
];

export const bottomMenu: TNavigation[] = [
  {
    id: 'profile',
    icon: ProfileIcon,
    list: [
      {
        id: 'myProfile',
        href: PATHS.PROFILE,
      },
      {
        id: 'userProfile',
        href: generatePath(PATHS.COMPANY_PROFILE_TAB, {
          tab: EUserCompanyProfileTabs.LICENSE,
        }),
      },
    ],
  },
  {
    id: 'help',
    href: PATHS.HELP,
    icon: HelpIcon,
  },
  {
    id: 'notifications',
    href: PATHS.NOTIFICATIONS,
    icon: NotificationsIcon,
  },
  {
    id: 'signout',
    href: PATHS.SIGN_OUT,
    icon: ExitIcon,
  },
];
