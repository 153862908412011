import {TItemId} from 'src/constants';
import {TBankDetails} from 'src/containers/User';

export enum EUserCompanyProfileTabs {
  PAYMENT = 'payment',
  LICENSE = 'license',
  REQUISITES = 'requisites',
  PRICING = 'pricing',
}

export const COMPANY_PROFILE_TABS = Object.values(EUserCompanyProfileTabs);

export enum EClientStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  DEACTIVATED = 'DEACTIVATED',
}

export enum ETemporaryBlockedTypes {
  BROADCASTS = 'BROADCASTS',
  TEMPLATES = 'TEMPLATES',
  CHANNELS = 'CHANNELS',
  CASCADES = 'CASCADES',
  OTHER = 'OTHER',
}

export type TGeneratePaymentLinkResponse = {downloadLink: string; amount: number; email: string};
export type TGeneratePaymentLinkPayload = {
  tenantId?: TItemId;
  bankDetails?: TBankDetails;
  amount: number;
};
