import {EMessagingLimit, EQualityScore, EQualityStatus, EStatus, EWabaStatus} from './definitions';

export default {
  channel: 'канал',
  searchPlaceholder: 'Быстрый поиск по названию или ID канала',
  filters: {
    channelLabel: 'Канал',
    channelStatuses: 'Статус канала',
    channels: 'Каналов',
  },
  status: {
    [EStatus.DRAFT]: 'Черновик',
    [EStatus.ACTIVE]: 'Работает исправно',
    [EStatus.PROBLEM]: 'Проблема',
    [EStatus.NEEDS_ATTENTION]: 'Требует внимания',
    [EStatus.DISABLED]: 'Отключен',
    [EStatus.REGISTRATION]: 'На регистрации',
    [EStatus.CANCELED]: 'Отменен',
  },
  wabaStatus: {
    [EWabaStatus.ACCOUNT_RESTRICTION]: 'Ограничен (WABA)',
  },
  qualityStatus: {
    [EQualityStatus.FLAGGED]: 'Предупреждение',
    [EQualityStatus.RESTRICTED]: 'Ограничен',
    [EQualityStatus.CONNECTED]: 'Работает исправно',
  },
  th: {
    channelId: 'Subject ID',
    type: 'Канал',
    name: 'Название канала',
    subject: 'Идентификатор',
    status: 'Статус канала',
  },
  whatsAppTechPartner: 'Канал WhatsApp для клиента',
  changeUrl: 'Обновить ссылку',
  copyUrl: 'Скопировать ссылку',
  deleteConfirm: 'Вы уверены, что хотите удалить канал "{{name}}", без возможности восстановления?',
  successRegister: {
    head: 'Анкета зарегистрирована!',
    text: 'Анкета регистрации заполнена и отправлена, самое нудное позади!<br /><br />Теперь статусы регистрации канала будут видны в списке каналов и в карточке канала.',
  },
  empty: {
    title: 'Здесь пока пусто',
    subtitle:
      'К сожалению, пока никто не пытался создать канал, чтобы отправлять пользователям сообщения. Ваш звездный час!',
  },
  editForm: {
    tariffLabel: 'Тариф для канала',
    tariffPlaceholder: 'Выберите тариф',
  },
  messages: {
    important: 'Важно!',
    needAcceptTariffs:
      'Чтобы зарегистрировать канал вы должны принять тарифы в Разделе “Тарифы” в <0>Профиле компании.</0>',
    noTariffs:
      'Для данного канала у вас нет доступных тарифов и вы пока не можете зарегистрировать его. Если вы хотите зарегистрировать канал, обратитесь к вашему менеджеру.',
  },
  qualityScoreLabel: 'Качество канала',
  limitLabel: 'Суточный лимит',
  messagesLimit: 'Суточный лимит исходящих сообщений',
  qualityUpdatedAt: 'Дата обновления лимита',
  activeChannel: 'Канал работает исправно',
  restrictedChannel: 'Работа канала временно ограничена',
  restrictedChannelDescription:
    'Для этого канала достигнут суточный лимит исходящих сообщений. Канал доступен только для входящих обращений.',
  flaggedChannel: 'В канале зафиксированы нарушения',
  flaggedChannelDescription:
    'На этот канал поступило много жалоб. Суточные лимиты могут быть снижены.',
  aboutLimits: 'Подробнее о лимитах',
  qualityWarning: 'Суточные лимиты могут быть снижены',
  flaggedWarning: 'Зафиксированы нарушения',
  qualityScore: {
    [EQualityScore.GREEN]: 'Высокое',
    [EQualityScore.YELLOW]: 'Среднее',
    [EQualityScore.RED]: 'Низкое',
    [EQualityScore.NA]: 'Неизвестно',
  },
  limit: {
    [EMessagingLimit.TIER_50]: '50',
    [EMessagingLimit.TIER_250]: '250',
    [EMessagingLimit.TIER_1K]: '1000',
    [EMessagingLimit.TIER_10K]: '10 000',
    [EMessagingLimit.TIER_100K]: '100 000',
    [EMessagingLimit.TIER_UNLIMITED]: 'Без ограничения',
    [EMessagingLimit.TIER_NOT_SET]: 'Неизвестен',
  },
};
