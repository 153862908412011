export default {
  ttl: `in {{hours}}{{minutes}}{{seconds}}
      to those who did not receive the message`,
  whatsAppQualityWarning:
    'The <0></0><b>{{name}}</b> channel has received many complaints. Daily limits can be reduced.',
  whatsAppQualityRestricted: `The broadcast may not be sent! The <0></0><b>{{name}}</b> channel has reached its daily limit of outgoing messages.
    <br/>Some messages may not be delivered.`,
  messagingLimit: 'Daily limit: <b>{{limit}}</b>{{messages}}',
  whatsAppQualityInfo: `The daily limit of the <0></0><b>{{name}}</b> channel is <b>{{limit}}</b>{{messages}}.
    <br/>Take this into account when planning your broadcast.`,
  messages: ' messages',
  wabaRestriction:
    'WhatsApp Business account {{wabaId}} to which <0></0><b>{{name}}</b> belongs has been restricted due to activity that violates the WhatsApp Business Terms of Service. {{reason}}<b>Outgoing messages cannot be sent until {{expirationDate}}</b>. <br/>The channel is only available to receive incoming messages.',
  wabaRestrictionReason: 'The reason is {{violationType}}. ',
  wabaDisabled:
    'WhatsApp Business account {{wabaId}} to which <0></0><b>{{name}}</b> belongs has been disabled due to activity that violates the WhatsApp Business Terms of Service. The channel has stopped working.',
  more: 'More details',
  time: {
    seconds_one: 'second',
    seconds_other: 'seconds',
    minutes_one: 'minute',
    minutes_other: 'minutes',
    hours_one: 'hour',
    hours_other: 'hours',
  },
};
