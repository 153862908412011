import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {LoadingButton, NewTextInputField, NewTextareaField} from '@edna/components';
import {Stack} from '@edna/components/primitives';

import {Message} from 'src/components';
import {Error} from 'src/components/primitives';
import {EBilledEventState} from 'src/constants';
import {joiResolver} from 'src/utils/validator';

import {
  useCancelStornoProcessMutation,
  useGetStornoDataMutation,
  useStartStornoProcessMutation,
} from '../api';
import * as S from '../style';
import {BilledEventData} from './BilledEventData';
import {BilledEventDetails} from './BilledEventDetails';
import {EPaymentCorrectionType, PAYMENT_CORRECTION_TYPES, TStornoFormData} from './definitions';
import {showConfirmModal, showSuccessModal} from './modals';

const formResolver = joiResolver((joi) =>
  joi.object({
    originalEventId: joi.string().trim().required(),
  }),
);

export const PaymentsTab = React.memo(() => {
  const {t} = useTranslation();

  const methods = useForm<TStornoFormData>({
    defaultValues: {
      correctionType: EPaymentCorrectionType.STORNO,
      originalEventId: null,
      comment: '',
    },
    resolver: formResolver,
  });

  const [startStornoProcess] = useStartStornoProcessMutation();
  const [cancelStornoProcess] = useCancelStornoProcessMutation();
  const {setValue} = methods;
  const {correctionType, originalEventId, comment} = methods.watch();

  const [
    searchStornoData,
    {reset: resetStornoSearch, data: billedEventData, isLoading, isError: isNotFound},
  ] = useGetStornoDataMutation();

  const handleSearchStorno = React.useCallback((values: TStornoFormData) => {
    searchStornoData(values.originalEventId!);
  }, []);

  const isAlreadyStorned =
    correctionType === EPaymentCorrectionType.STORNO &&
    [EBilledEventState.STORNO, EBilledEventState.STORNED].includes(
      billedEventData?.transaction?.state as EBilledEventState,
    );

  const resetBilledEventData = React.useCallback(() => {
    resetStornoSearch();
    setValue('originalEventId', '');
    setValue('comment', '');
  }, [setValue, resetStornoSearch]);

  const handleStartStornoProcess = React.useCallback(async () => {
    try {
      await startStornoProcess({originalEventId, comment}).unwrap();

      showSuccessModal({onClose: resetBilledEventData});
    } catch {}
  }, [comment, originalEventId, resetBilledEventData]);

  const handleCancelStornoProcess = React.useCallback(async () => {
    if (!originalEventId) {
      return;
    }

    try {
      await cancelStornoProcess(originalEventId).unwrap();

      showSuccessModal({onClose: resetBilledEventData});
    } catch {}
  }, [originalEventId, resetBilledEventData]);

  const submitFunctions = React.useMemo(
    () => ({
      [EPaymentCorrectionType.STORNO]: handleStartStornoProcess,
      [EPaymentCorrectionType.CANCEL_STORNO]: handleCancelStornoProcess,
    }),
    [handleCancelStornoProcess, handleStartStornoProcess],
  );

  const handleCorrection = React.useCallback(() => {
    if (!correctionType) {
      return;
    }

    return showConfirmModal({
      correctionType,
      billedEventData,
      onSubmit: submitFunctions[correctionType],
      onCancel: resetBilledEventData,
    });
  }, [correctionType, resetBilledEventData, billedEventData, submitFunctions]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSearchStorno)}>
        <Stack size="4">
          <S.SelectField
            name="correctionType"
            label={t('Corrections:payments.selectType')}
            onChange={resetBilledEventData}
            isDisplayEmpty={false}
            options={PAYMENT_CORRECTION_TYPES.map((type) => ({
              label: t(`Corrections:payments.type.${type}`),
              value: type,
            }))}
          />
          <Stack size="5" direction="ROW" isFullWidth align="start">
            <NewTextInputField
              name="originalEventId"
              label={t('Corrections:payments.enterEventId')}
              noMargin
            />
            <S.SearchButton appearance="primary" loading={isLoading} type="submit">
              {t(`Corrections:payments.search`)}
            </S.SearchButton>
          </Stack>
          {isNotFound && (
            <div>
              <S.BigText secondary>{t('Corrections:payments.searchResults')}</S.BigText>
              <Error>
                <S.StatusIcon name="error" />
                {t(`Corrections:payments.notFound`)}
              </Error>
            </div>
          )}
          {billedEventData && (
            <Stack isFullWidth>
              <S.BigText secondary>{t('Corrections:payments.searchResults')}</S.BigText>
              <BilledEventDetails billedEventData={billedEventData} />
              <Message type="Warning" fontSize={'S'} hasFullWidth>
                {t(`Corrections:payments.isSearchCorrectMessage`)}
              </Message>
              <BilledEventData data={billedEventData} />
            </Stack>
          )}
          {correctionType === EPaymentCorrectionType.STORNO && (
            <NewTextareaField name="comment" label={t('Corrections:payments.comment')} noMargin />
          )}
          <LoadingButton
            appearance="primary"
            onClick={handleCorrection}
            disabled={!billedEventData || isAlreadyStorned}
          >
            {t(`Corrections:payments.button.${correctionType}`)}
          </LoadingButton>
        </Stack>
      </form>
    </FormProvider>
  );
});

PaymentsTab.displayName = 'PaymentsTab';
