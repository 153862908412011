import {EPaymentCorrectionType} from './PaymentsTab';
import {ETariffActionType} from './TariffsTab';
import {ECorrectionsTab} from './definitions';

export default {
  tabs: {
    [ECorrectionsTab.PAYMENTS]: 'Списания и оплаты',
    [ECorrectionsTab.LICENSES]: 'Лицензии',
    [ECorrectionsTab.TARIFFS]: 'Изменения тарифов',
  },
  payments: {
    storno: 'Сторнировать операцию',
    selectType: 'Выберите тип операции',
    notFound: 'Операция не найдена',
    type: {
      [EPaymentCorrectionType.STORNO]: 'Сторнировать операцию',
      [EPaymentCorrectionType.CANCEL_STORNO]: 'Отменить сторно',
    },
    enterEventId: 'Укажите ID операции',
    searchResults: 'Результаты поиска',
    companyName: 'Наименование компании',
    paymentType: 'Тип списания',
    licensePayment: 'Списание за лицензию',
    amount: 'Сумма списания',
    currency: 'Валюта списания',
    isSearchCorrectMessage: 'Обязательно убедитесь в корректности запроса по таблицам',
    billedEvent: 'Billed event',
    debtTransaction: 'Debt transaction',
    transaction: 'Transaction',
    id: 'ID',
    originalEventId: 'Original event ID',
    originalEventType: 'Original event Type',
    confirmation: 'Confirmation',
    createdAt: 'Дата',
    eventId: 'Event ID',
    tenantId: 'ID компании',
    reason: 'Reason',
    reasonCode: 'Reason code',
    balanceId: 'Balance ID',
    openingBalance: 'Opening balance',
    state: 'Статус',
    updated: 'Обновлено',
    search: 'Найти',
    comment: 'Комментарий',
    button: {
      [EPaymentCorrectionType.STORNO]: 'Сторнировать',
      [EPaymentCorrectionType.CANCEL_STORNO]: 'Отменить сторно',
    },
    confirmModal: {
      title: {
        [EPaymentCorrectionType.STORNO]: 'Уверены? Мы отменяем списание',
        [EPaymentCorrectionType.CANCEL_STORNO]: 'Уверены? Мы отменяем сторно',
      },
      message: 'Если это ошибочная операция, то исправить это будет невозможно',
      subtitle: 'Проверьте данные:',
      button: 'Да, продолжить',
    },
    successModal: {
      title: 'Все хорошо!',
      message: 'Запрос принят к исполнению',
    },
  },
  licenses: {
    channelType: 'Укажите тип канала',
    licenseId: 'Укажите ID лицензии',
    month: 'Номер месяца',
    year: 'Год',
    eventTable: 'license_billed_event_tab (по указанному периоду)',
    notFound: 'Лицензия не найдена',
    search: 'Поиск',
    notification: 'Операция пролонгации лицензии запущена',
    tr: {
      id: 'ID',
      channel: 'Канал',
      price: 'Стоимость',
      currency: 'Валюта',
      paymentType: 'Тип списания',
      periodStartedAt: 'Начало',
      periodEndedAt: 'Окончание',
      transactionId: 'ID транзакции',
      createdAt: 'Создана',
      updatedAt: 'Обновлена',
      documentId: 'ID документа',
      tenantId: 'ID тенанты',
      subjectId: 'ID подписи',
      periodMonths: 'Период продления, мес',
      name: 'Название',
      description: 'Примечание',
      licenseStatus: 'Статус лицензии',
      activatedAt: 'Дата активации',
      expiredAt: 'Лицензия действительна до',
      cancelledAt: 'Отменена',
      activeUntil: 'Активирована с ',
      paidUntil: 'Оплачена до',
      temporaryblockedAt: 'Временно заблокировано c',
      paymentCode: 'Код списания',
    },
  },
  tariffs: {
    updateInfo:
      'В тарифах изменяются только данные, которые вы ввели в процессе изменения (через UI или из файла). Незаполненные данные в тарифах не обновятся.',
    updateWarning:
      'Вы собираетесь массово изменить тарифы. Убедитесь в корректности  данных перед изменением!',
    mainSection: 'Массовое действие с тарифом',
    actionType: 'Выберите действие с тарифом',
    actionTypePlaceholder: 'Действие с тарифом',
    tariffType: 'Выберите тип тарифа',
    tariff: 'Тариф',
    tariffTemplate: 'Шаблон тарифа',
    channelType: 'В каком канале меняется тариф',
    channelTypePlaceholder: 'Выберите канал',
    newSection: 'Новые данные для тарифа',
    licensePrice: 'Изменить стоимость лицензии',
    licensePriceWA: 'Изменить стоимость лицензии (licensePrice)',
    mauCount: 'Количество MAU (mauCount)',
    initPayment: 'Подключение (initPayment)',
    defaultMauPrice: 'Стоимость AMAU (defaultMauPrice)',
    file: 'Загрузите согласованный файл с индивидуальной стоимостью сообщений или диалогов',
    date: 'Тариф вступит в силу',
    tariffIds: 'Перечислите ID тарифов для изменения',
    comment: {
      [ETariffActionType.REPLACE]: 'Комментарий по изменению',
      [ETariffActionType.UPDATE]: 'Комментарий по изменению',
      [ETariffActionType.DELETE]: 'Комментарий по удалению',
    },
    commentPlaceholder: 'К примеру, номер обращения в саппорт, по которому делаются эти изменения',
    actionOption: {
      [ETariffActionType.REPLACE]: 'Полная замена',
      [ETariffActionType.UPDATE]: 'Обновление тарифа',
      [ETariffActionType.DELETE]: 'Удалить параметры тарифа',
    },
    submitButton: {
      [ETariffActionType.REPLACE]: 'Изменить параметры',
      [ETariffActionType.UPDATE]: 'Изменить параметры',
      [ETariffActionType.DELETE]: 'Удалить параметры',
    },
    successTariffTemplateMessage: 'Параметры шаблонов тарифов успешно обновлены',
    successTariffMessage: {
      [ETariffActionType.REPLACE]: 'Запланирована полная замена параметров тарифов на {{date}}',
      [ETariffActionType.UPDATE]: 'Запланировано массовое изменение параметров тарифов на {{date}}',
      [ETariffActionType.DELETE]: 'Запланировано массовое удаление параметров тарифов на {{date}}',
    },
    confirmModal: {
      title: {
        [ETariffActionType.REPLACE]: 'Уверены? Вы собираетесь полностью заменить параметры тарифов',
        [ETariffActionType.UPDATE]: 'Уверены? Вы собираетесь массово изменить параметры тарифов',
        [ETariffActionType.DELETE]: 'Уверены? Вы собираетесь массово удалить параметры тарифов',
      },
      check: 'Проверьте данные:',
      channel: 'Канал:',
      tariffType: 'Тип тарифа:',
      action: 'Действие с тарифом:',
      actionOption: {
        [ETariffActionType.REPLACE]: 'Полная замена параметров тарифа',
        [ETariffActionType.UPDATE]: 'Изменение параметров тарифа',
        [ETariffActionType.DELETE]: 'Удаление параметров тарифа',
      },
      licensePrice: 'Стоимость лицензии:',
      mauCount: 'Количество MAU:',
      initPayment: 'Подключение:',
      defaultMauPrice: 'Стоимость AMAU:',
      file: 'Прикрепленный файл:',
      date: 'Дата вступления в силу изменения:',
      tariffIds: 'ID изменяемых тарифов:',
    },
  },
};
