import {EContentType} from 'src/constants';

import {EMenuButtonAction} from '../MenuButton';
import {ETemplateButtonOtpType} from '../MessageMatchers/WhatsAppForm/definitions';
import {EMessageMatcherButtonTypes} from '../MessageMatchers/definitions';
import {ECommonMenuType} from './Layout/constants';
import {EFlowStatus, EFlowStepType, EUIActionType, EUIConditionType} from './types';

export default {
  createTrigger: 'chain',
  status: {
    [EFlowStatus.DRAFT]: 'Draft',
    [EFlowStatus.ACTIVE]: 'Active',
    [EFlowStatus.DELETED]: 'Deleted',
    [EFlowStatus.INACTIVE]: 'Inactive',
    [EFlowStatus.SCHEDULED]: 'Scheduled',
    [EFlowStatus.FINISHED]: 'Finished',
  },
  type: {
    [EFlowStepType.TRIGGER]: 'Start block',
    [EFlowStepType.CONDITION]: 'Condition',
    [EFlowStepType.ACTION]: 'Action',
  },
  changeCondition: 'Change condition',
  transitions: 'Transitions: {{count}}',
  description: {
    [EFlowStepType.TRIGGER]: 'Select a starting condition for your script',
    [EFlowStepType.CONDITION]: 'Select condition',
    [EFlowStepType.ACTION]: 'Select action',
    [EUIActionType.CC_DIALOG_SWITCH_ACTION]:
      'Dialogue with the recipient will continue in edna Chat Center',
    [EUIActionType.PAUSE_ACTION]:
      'The recipient will not receive any messages from the script during this pause',
    [EUIActionType.ADD_SUBSCRIBER_TAG_ACTION]: 'Select tags to assign to all recipients',
    [EUIActionType.DELETE_SUBSCRIBER_TAG_ACTION]: 'Select tags to remove from recipients data',
    [EUIConditionType.TAG_PRESENCE_CONDITION]: 'Select recipients based on tags',
    [EUIConditionType.BUTTON_PRESSED]: 'Select recipients based on buttons that they click',
    [EUIConditionType.MESSAGE_RECEIVED]: 'Select recipients who responded with a message',
  },
  title: {
    [EUIActionType.PAUSE_ACTION]: 'Enter waiting time',
  },
  trigger: {
    [EUIConditionType.MESSAGE_RECEIVED]: {
      title: 'Incoming message received',
      subtitle: 'Recipient sent a message',
    },
    [EUIConditionType.BUTTON_PRESSED]: {
      title: 'Button click',
      buttonLabel: 'Button name',
      buttonId: 'Button code',
    },
    [EUIConditionType.TAG_ASSIGN_TIMEOUT_CONDITION]: {
      title: 'Time since tag was assigned',
      delay: 'Time since tag was assigned to a recipient',
      tags: 'Tag assigned to a recipient',
    },
    [EUIConditionType.TAG_PRESENCE_CONDITION]: {
      title: 'Recipients with tags',
      include: 'Include',
      exclude: 'Exclude',
    },
    [EUIActionType.ADD_SUBSCRIBER_TAG_ACTION]: 'Assign tags',
    [EUIActionType.DELETE_SUBSCRIBER_TAG_ACTION]: 'Remove tags',
    [EUIActionType.CC_DIALOG_SWITCH_ACTION]: 'Chat with agent',
    [EUIActionType.SEND_MESSAGE_ACTION]: '[en] Отправить шаблонное сообщение WhatsApp',
    [EUIActionType.CREATE_MESSAGE_ACTION]: 'Send a WhatsApp message',
    [EUIActionType.PAUSE_ACTION]: 'Wait for response',
  },
  triggerDescription: {
    [EUIActionType.CC_DIALOG_SWITCH_ACTION]: 'Switch to edna Chat Center',
  },
  createMessageAction: {
    messageText: 'Message text',
    addButtons: 'Add buttons',
    addButton: 'Add button',
    buttonsLimit: '[en] До 3х кнопок типа quick reply',
    buttonTitle: 'Chat button',
    buttonText: 'Button text (maximum 25 characters)',
    buttonCode: 'Button code, payload (maximum 250 characters)',
  },
  pauseAction: {
    createMessageHint:
      '[en] Если следующим блоком будет Действие «Отправить сообщение WhatsApp», помните, что ожидание не может быть более 23ч 59м, так как нешаблонное сообщение можно отправить только в течение 24 ч после последнего ответа клиента',
  },
  sendMessageAction: {
    template: 'Template',
    ignoreSchedule: 'Ignore broadcast schedule',
    messageMatcherId: 'Select a message template',
    hasImage:
      'This template has an image in the header. Upload the image from your computer or enter a link to the image',

    hasAttachment: {
      [EContentType.IMAGE]:
        'This template has an image in the header. Upload the image from your computer or enter a link to the image.',
      [EContentType.VIDEO]:
        'This template has a video in the header. Upload the video from your computer or enter a link to the video.',
      [EContentType.DOCUMENT]:
        'This template has a document in the header. Upload the document from your computer or enter a link to the document.',
    },
    upload: 'Upload',
    addLink: 'Insert link to file',
    imageHint: 'Image (PNG or JPG up to 5 MB)',
    viewTemplate: 'Preview the template',
    linkHint: {
      [EContentType.IMAGE]: 'Image link (no more than 200 characters)',
      [EContentType.VIDEO]: 'Video link (no more than 200 characters)',
      [EContentType.DOCUMENT]: 'Document link (no more than 200 characters)',
    },
    button: 'Button',
    buttonName: 'Button name',
    buttonText: 'Button text',
    buttonCode: 'Button code',
    buttonType: 'Button type',
    buttonTypeLabel: {
      [EMessageMatcherButtonTypes.PHONE]: 'Call button',
      [EMessageMatcherButtonTypes.URL]: 'Link button',
      [EMessageMatcherButtonTypes.CHAT]: 'Chat button',
      [ETemplateButtonOtpType.COPY]: 'Copy code',
      [ETemplateButtonOtpType.AUTO]: 'One-tap autofill',
    },
    staticUrl: 'Static part of the URL',
    dynamicUrl: 'Dynamic part of the URL',
    phoneNumber: 'Phone number',
    viewMessage: 'View message',
    attachFile: 'Upload file',
    back: 'Back',
    done: 'Done',
    dynamicUrlRequired: 'Dynamic link component cannot be empty',
  },
  forms: {
    [EUIConditionType.BUTTON_PRESSED]: {
      button: 'Button',
      buttonLabel: 'Button name (must match the template)',
      buttonId: 'Button code (must match the template)',
      addButton: 'Add button',
    },
    [EUIConditionType.TAG_ASSIGN_TIMEOUT_CONDITION]: {
      delay: 'Time since tag was assignment to a recipient',
      tags: 'Tag assigned to a recipient',
    },
    [EUIConditionType.TAG_PRESENCE_CONDITION]: {
      tagsTree: 'Tag tree',
    },
  },
  tag: 'Tag',
  systemWaiting: 'Waiting for',
  condition: {
    yes: 'Yes',
    no: 'No',
  },
  contextMenu: {
    add: 'Add',
    deleteEdge: 'Delete',
    deleteBlock: 'Delete block?',
    duplicateBlock: 'Duplicate block',
    deleteTrigger: 'Delete trigger?',
    duplicateTrigger: 'Duplicate trigger',
  },
  addButton: {
    [EFlowStepType.TRIGGER]: 'Select a condition',
    [EFlowStepType.CONDITION]: 'Select a condition',
    [EFlowStepType.ACTION]: 'Select an action',
  },
  menu: {
    [EFlowStepType.ACTION]: 'Action',
    [EFlowStepType.CONDITION]: 'Condition',

    [ECommonMenuType.SETTINGS]: 'Settings',
    [ECommonMenuType.HELP]: 'Help',
    [ECommonMenuType.NOTIFICATIONS]: 'Notifications',
    [ECommonMenuType.SIGN_OUT]: 'Signout',
  },
  actionButton: {
    [EMenuButtonAction.START]: 'Start',
    [EMenuButtonAction.STOP]: 'Stop',
    [EMenuButtonAction.SETTINGS]: 'Settings',
    [EMenuButtonAction.VIEW]: 'View',
    [EMenuButtonAction.EDIT]: 'Edit',
    [EMenuButtonAction.COPY]: 'Copy',
    [EMenuButtonAction.CANCEL]: 'Cancel',
    [EMenuButtonAction.DELETE]: 'Delete',
  },
  filters: {
    searchPlaceholder: 'Quick search by name',
    flows: 'Scripts',
  },
  th: {
    activatedAt: 'Start date',
    name: 'Script name',
    status: 'Status',
    started: 'Started',
    startedHint: 'How many recipients were processed by the starting block of the script',
    inProgress: 'Running',
    inProgressHint: 'How many recipients are going through the script',
    finished: 'Completed',
    finishedHint: 'How many recipients completed the script successfully',
  },
  modal: {
    [EMenuButtonAction.STOP]: {
      title: 'Stop the script?',
      message:
        "If you stop the script, your conversations with recipients will be terminated. Subscribers participating in the scenario won't be able to complete it.",
      confirmButton: 'Stop',
    },
    [EMenuButtonAction.CANCEL]: {
      title: 'Cancel the script?',
      message:
        "You can't restart a cancelled script. To use this scenario again, you must duplicate and save it with a new name and schedule.",
      confirmButton: 'Cancel start',
    },
    [EMenuButtonAction.DELETE]: {
      title: 'Delete the script?',
      message: 'This will also delete all settings in the script. Are you sure?',
      confirmButton: 'Delete',
    },
    [EMenuButtonAction.COPY]: {
      title: 'Copy the script?',
      message:
        'All blocks and links in the script will be copied. The copy requires a new name and schedule.',
      confirmButton: 'Copy',
    },
    [EMenuButtonAction.START]: {
      title: 'Your script started successfully!',
      message: '',
      confirmButton: 'Ок',
    },
    [EFlowStepType.TRIGGER]: {
      title: 'Choose the trigger to start your chain from',
    },
    [EFlowStepType.CONDITION]: {
      title: 'Select a condition',
    },
    [EFlowStepType.ACTION]: {
      title: 'Select an action',
    },
  },
  notification: {
    activateSuccess: 'Your script started successfully!',
  },
  errors: {
    tagsRequired: 'You must select at least one tag',
    missingData: "Block settings are missing. The script can't be started.",
    missingTriggerData: 'You must select at least one condition for your start block',
    notLastFlowStep: "A condition can't be the last block in a script",
    messageTimeoutTooLong:
      '[en] Действие невозможно: отправить нешаблонное сообщение можно только в течение 24ч с момента ответа пользователя. Измените действие или время ожидания в предыдущем блоке',
  },
};
