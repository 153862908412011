export default {
  ttl: `через {{hours}}{{minutes}}{{seconds}}
      тем, кто не получил сообщение`,
  whatsAppQualityWarning:
    'На канал <0></0><b>{{name}}</b> поступило много жалоб, есть риск понижения лимитов.',
  whatsAppQualityRestricted: `Рассылка может не уйти! Для канала <0></0><b>{{name}}</b> был исчерпан суточный лимит исходящих сообщений.
    <br/>Некоторые сообщения могут быть не доставлены.`,
  messagingLimit: 'Суточный лимит: <b>{{limit}}</b>{{messages}}',
  whatsAppQualityInfo: `Суточный лимит канала <0></0><b>{{name}}</b> - <b>{{limit}}</b>{{messages}}.
    <br/>Учитывайте это при планировании рассылки`,
  messages: ' сообщений',
  wabaRestriction:
    'Бизнес-аккаунт WhatsApp {{wabaId}}, к которому относится <0></0><b>{{name}}</b>, ограничен из-за нарушения правил или политики использования WhatsApp. {{reason}}<b>Отправка исходящих сообщений невозможна до {{expirationDate}}</b>. <br/>Канал доступен только для приема входящих сообщений.',
  wabaRestrictionReason: 'Причина — {{violationType}}. ',
  wabaDisabled:
    'Бизнес-аккаунт WhatsApp {{wabaId}}, к которому относится <0></0><b>{{name}}</b>, отключен из-за нарушения правил или политики использования WhatsApp. Работа канала остановлена.',
  more: 'Подробнее',
  time: {
    seconds_one: 'секунду',
    seconds_few: 'секунды',
    seconds_many: 'секунд',
    minutes_one: 'минуту',
    minutes_few: 'минуты',
    minutes_many: 'минут',
    hours_one: 'час',
    hours_few: 'часа',
    hours_many: 'часов',
  },
};
