import isEmpty from 'lodash/isEmpty';

import {TItemId} from 'src/constants';
import {CACHE_LIFETIME, ECacheTag, rootApi} from 'src/models';

import {EAutopaymentStatus, TAutopayment, TAutopaymentRestrictions, TFormData} from './definitions';

const api = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    stripeRedirect: builder.query<{stripeUrl: string}, void>({
      query: () => ({
        method: 'GET',
        url: '/stripe/checkout/customer-portal',
      }),
    }),
    getAutopaymentInfo: builder.query<TAutopayment | undefined, TItemId | void>({
      query: (supportTenantId) => ({
        method: 'GET',
        url:
          supportTenantId !== undefined
            ? `/stripe/checkout/support/${supportTenantId}/customer-info`
            : '/stripe/checkout/customer-info',
      }),
      keepUnusedDataFor: CACHE_LIFETIME,
      providesTags: [ECacheTag.AUTOPAYMENT],
      transformResponse: (data: TAutopayment) => {
        if (isEmpty(data) || data?.customerStatus === EAutopaymentStatus.NEW) {
          return undefined;
        }

        return data;
      },
    }),
    getAutopaymentRestrictions: builder.query<TAutopaymentRestrictions, void>({
      query: () => ({
        method: 'GET',
        url: '/stripe/checkout/restrictions',
      }),
      keepUnusedDataFor: CACHE_LIFETIME,
    }),
    connectAutopayment: builder.mutation<TAutopayment, TFormData>({
      query: (data) => ({
        method: 'POST',
        url: '/stripe/checkout/setup-customer',
        data,
        meta: {isShowError: true},
      }),
      invalidatesTags: [ECacheTag.AUTOPAYMENT],
    }),
    updateAutopaymentSettings: builder.mutation<TAutopayment, TFormData>({
      query: (data) => ({
        method: 'PUT',
        url: '/stripe/checkout/update-customer',
        data,
        meta: {isShowError: true},
      }),
      invalidatesTags: [ECacheTag.AUTOPAYMENT],
    }),
    offAutopayment: builder.mutation<void, TItemId | void>({
      query: (supportTenantId) => ({
        method: supportTenantId !== undefined ? 'PUT' : 'GET',
        url:
          supportTenantId !== undefined
            ? `/stripe/checkout/support/${supportTenantId}/disable-customer`
            : '/stripe/checkout/request-deactivation',
        meta: {isShowError: true},
      }),
      invalidatesTags: (_, __, supportTenantId) => (supportTenantId ? [ECacheTag.AUTOPAYMENT] : []),
    }),
    chargeAutopayment: builder.mutation<void, TItemId>({
      query: (supportTenantId) => ({
        method: 'POST',
        url: `/stripe/checkout/support/${supportTenantId}/initiate-auto-replenishment`,
        meta: {isShowError: true},
      }),
      invalidatesTags: (_, __, supportTenantId) => (supportTenantId ? [ECacheTag.AUTOPAYMENT] : []),
    }),
    restoreAutopayment: builder.mutation<void, TItemId | void>({
      query: (supportTenantId) => ({
        method: 'POST',
        url: supportTenantId
          ? `/stripe/checkout/support/${supportTenantId}/enable-customer`
          : '/stripe/checkout/enable-customer',
        meta: {isShowError: true},
      }),
      invalidatesTags: [ECacheTag.AUTOPAYMENT],
    }),
  }),
});

export const {
  useLazyStripeRedirectQuery,
  useGetAutopaymentInfoQuery,
  useGetAutopaymentRestrictionsQuery,
  useOffAutopaymentMutation,
  useConnectAutopaymentMutation,
  useChargeAutopaymentMutation,
  useUpdateAutopaymentSettingsMutation,
  useRestoreAutopaymentMutation,
} = api;
