import {EChannelType} from 'src/constants';
import {rootApi} from 'src/models/api';
import {parseISO} from 'src/utils';

import {TLicenseData} from './LicensesTab';
import {TBilledEvent, TBilledEventData, TStornoFormData} from './PaymentsTab';
import {TTariffUpdate} from './TariffsTab';

const api = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getStornoData: builder.mutation<TBilledEventData, TBilledEvent['originalEventId']>({
      query: (eventId) => ({
        method: 'GET',
        url: `/billing/storno/find/${eventId}`,
        meta: {
          isShowError: true,
        },
      }),
    }),
    startStornoProcess: builder.mutation<
      TBilledEventData,
      Pick<TStornoFormData, 'originalEventId' | 'comment'>
    >({
      query: (data) => ({
        method: 'POST',
        url: '/billing/storno',
        data,
        meta: {
          isShowError: true,
        },
      }),
    }),
    cancelStornoProcess: builder.mutation<TBilledEventData, TBilledEvent['originalEventId']>({
      query: (eventId) => ({
        method: 'POST',
        url: '/billing/storno/cancel',
        data: {originalEventId: eventId},
        meta: {
          isShowError: true,
        },
      }),
    }),
    getLicenseRenewal: builder.mutation<
      TLicenseData,
      {
        licenseId: number | string;
        month: string;
        year: string;
      }
    >({
      query: (payload) => ({
        method: 'GET',
        url: '/admin/billing/license/renewal',
        params: {
          licenseId: payload.licenseId,
          period: `${payload.year}-${payload.month}`,
        },
        meta: {
          isShowError: true,
        },
      }),
    }),
    renewLicense: builder.mutation<
      TLicenseData,
      {
        licenseId: number | string;
        month: string;
        year: string;
        channelType: EChannelType;
      }
    >({
      query: (payload) => ({
        method: 'POST',
        url: `/billing/jobs/license/renewal/${payload.channelType.toUpperCase()}/period/${
          payload.year
        }-${payload.month}`,
        params: {
          licenseIds: payload.licenseId,
        },
        meta: {
          isShowError: true,
          isShowSuccess: true,
          successMessageKey: 'Corrections:licenses.notification',
        },
      }),
    }),
    changeTariffs: builder.mutation<void, TTariffUpdate>({
      query: ({file, isTariffTemplate, ...data}) => {
        const meta = {
          isShowError: true,
          isShowSuccess: true,
          successMessageKey: isTariffTemplate
            ? 'Corrections:tariffs.successTariffTemplateMessage'
            : `Corrections:tariffs.successTariffMessage.${data.action}`,
          i18nOptions: {
            date: isTariffTemplate ? undefined : parseISO(data.from, 'dd.MM.yyyy, HH:mm'),
          },
        };

        if (file) {
          const formData = new FormData();

          formData.append('file', file);
          formData.append(
            'request',
            new Blob([JSON.stringify(data)], {
              type: 'application/json',
            }),
          );

          return {
            method: 'POST',
            url: isTariffTemplate
              ? '/billing/tariff/template/manager/update'
              : '/billing/tariff/manager/update',
            data: formData,
            meta,
          };
        }

        return {
          method: 'POST',
          url: isTariffTemplate
            ? '/billing/tariff/template/manager/update-license'
            : '/billing/tariff/manager/update-license',
          data,
          meta,
        };
      },
    }),
  }),
});

export const {
  // Payments
  useGetStornoDataMutation,
  useStartStornoProcessMutation,
  useCancelStornoProcessMutation,
  // Licenses
  useRenewLicenseMutation,
  useGetLicenseRenewalMutation,
  // Tariffs
  useChangeTariffsMutation,
} = api;
