export default {
  attachFile: 'Attach file',
  attachFileWithExtension: 'Attach {{extension}} file',
  attachAnotherFile: 'Attach another file',
  uploadFile: 'Upload file',
  uploadFileWithExtension: 'Upload {{extension}}',
  uploadAnotherFile: 'Upload another file',
  invalidFileFormat: 'File upload error: allowed file format is {{formats}}',
  invalidFileSize: 'File upload error: maximum file size is {{size}} MB',
};
