export default {
  attachFile: 'Прикрепить файл',
  attachFileWithExtension: 'Прикрепить файл {{extension}}',
  attachAnotherFile: 'Прикрепить другой файл',
  uploadFile: 'Загрузить файл',
  uploadFileWithExtension: 'Загрузить {{extension}}',
  uploadAnotherFile: 'Загрузить другой файл',
  invalidFileFormat: 'Ошибка загрузки файла: допустимый формат файла - {{formats}}',
  invalidFileSize: 'Ошибка загрузки файла: максимальный размер файла - {{size}} МБ',
};
